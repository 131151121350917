import { PageProps } from "gatsby";
import React from "react";
import { ApplicationsPage } from "../../../borrowing/applications/applications-page.component";
import { analyticsViewPageEvent } from "../../../shared/analytics/analytics.utils";
import { isBrowser } from "@wisr/common";
import style from "./index.scss";

const LoansApplication: React.FC<PageProps> = () => {
  if (isBrowser()) {
    analyticsViewPageEvent("Borrowing");
  } else {
    return null;
  }
  return (
    <div className="content">
      <style jsx>{style}</style>
      <h1>Applications</h1>
      <ApplicationsPage />
    </div>
  );
};

export default LoansApplication;
