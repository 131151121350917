import { isDefined, useLoadingStatus } from "@wisr/common";
import { ContentScroller, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isNotEmpty } from "../../shared/common.utils";
import { ApplicationOverviewBorrowingWidgetComponent } from "../../widgets/borrowing/overview/application/application-overview-borrowing-widget.component";
import overviewStyle from "../../widgets/borrowing/overview/overview-borrowing-widget.scss";
import { loanBorrowingGetAction } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.actions";
import { GETLOAN_BORROWING_LOADING } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.epic";
import { ErrorWidgetComponent } from "../../widgets/shared/error/error-widget.component";
import { WidgetComponent } from "../../widgets/widget.component";
import { useGetIncompleteApplications } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";

export const ApplicationsPage = () => {
  const [loading, loadingError] = useLoadingStatus(GETLOAN_BORROWING_LOADING);

  const [customerApplications, setCustomerApplications] =
    useState<JSX.Element[]>();
  const dispatch = useDispatch();

  const applications = useGetIncompleteApplications();

  React.useEffect(() => {
    if (!isNotEmpty(applications)) {
      dispatch(loanBorrowingGetAction());
    }
  }, [applications, dispatch]);

  React.useEffect(() => {
    if (applications && applications.length > 0) {
      const customerApps = applications.map((application, index) => {
        return (
          <ApplicationOverviewBorrowingWidgetComponent
            application={application}
            key={application.loanId}
            status={"incomplete"}
            active={index === 0}
          />
        );
      });
      setCustomerApplications(customerApps);
    }
  }, [applications]);

  if (loadingError) {
    return (
      <WidgetComponent
        widgetName="applications-overview-error"
        widgetType="borrow"
      >
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  return (
    <div className="overview-loans-wrapper">
      <style jsx>{overviewStyle}</style>
      {loading || !isDefined(customerApplications) ? (
        <div className="loading-wrapper">
          <WidgetComponent widgetName="borrow-overview" widgetType="borrow">
            <SkeletonLoader lines={7} />
          </WidgetComponent>
        </div>
      ) : (
        <div className="loans">
          <ContentScroller mobileOnly>{customerApplications}</ContentScroller>
        </div>
      )}
    </div>
  );
};
